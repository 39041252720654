@import "variable";
@import "FormFilter";
@import "Form";
@import "Container";

.modal-backdrop {
  min-width: 100%;
  min-height: 100%;
}

.statut {
  display: flex !important;
  align-items: center;

  &.toControl:before  {
    background-color: $toControl;
  }

  &.controlled:before {
    background-color: $controlled;
  }
  
  &.inProgress:before {
    background-color: $inProgress;
  }

  &.completed:before {
    background-color: $completed;
  }

  &:before {
    content: '';
    display: block;
    float: left;
    margin-right: 5px;
    width: 4px;
    height: 40px;
    border-radius: 10px;
  }
}
.fa-custom-word,
.fa-custom-pdf,
.fa-custom-trash {
    cursor: pointer;
    margin-right: 10px;
}