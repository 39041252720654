@import "~@suezenv/react-theme-components/assets/scss/1-settings/bootstrap";
@import "../variable";

$statusColumnSize: 150px;
$idcPdcColumnSize: 150px;

.pdc-table-wrapper {
    height: 85%;
    div:nth-child(1) {
        height: 100%;
    }
    .sticky-header{
        position: sticky;
        top:0
    }
    .react-bootstrap-table {
        overflow: auto;
        height: 100%;
    }

    .survey-pagination {
        margin-top: 10px;
    }
}

.pdc-table {
    td {
        vertical-align: middle !important;
    }

    td, th {
        .checkbox-container {
            display: block;
            position: relative;
            margin-right: 32px;
            margin-left: 10px;
            margin-bottom: 25px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
            
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #eee;
            }
            
            &:hover input ~ .checkmark {
                background-color: #ccc;
            }
            
            input:checked ~ .checkmark {
                background-color: #030f40;
            }
            
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }
            
            input:checked ~ .checkmark:after {
                display: block;
            }
            
            .checkmark:after {
                left: 8px;
                top: 4px;
                width: 9px;
                height: 14px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        .radio-container {
            /* The container */
            display: block;
            position: relative;
            margin-right: 32px;
            margin-left: 10px;
            margin-bottom: 25px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
            }
            
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 26px;
                width: 26px;
                background-color: #eee;
                border-radius: 50%;
            }
            
            &:hover input ~ .checkmark {
                background-color: #ccc;
            }
            
            input:checked ~ .checkmark {
                background-color: #030f40;
            }
            
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }
            
            input:checked ~ .checkmark:after {
                display: block;
            }
            
            .checkmark:after {
                top: 8px;
                left: 8px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: white;
            }
        }
    }

    thead{
        position: sticky;
        top:0;
        z-index: 2;
    }
    .fixed-column {
        position: sticky; 
        position: -webkit-sticky;
        z-index:1; 
        left:0; 
        background: var(--white);
    }

    tbody tr td:nth-child(1) {
        @extend .fixed-column;
        padding: 0;
        width: fit-content;
    }

    thead tr th:nth-child(1) { 
        @extend .fixed-column;
        padding: 0;
        background: var(--blue-clear-light);
        width: fit-content;
    }

    tbody tr td:nth-child(2) {
        @extend .fixed-column;
        padding: 5px;
        width:$statusColumnSize;
        max-width:$statusColumnSize;
        min-width: $statusColumnSize;
    }

    thead tr th:nth-child(2) { 
        @extend .fixed-column;
        padding: 5px;
        padding-left: 15px;
        background: var(--blue-clear-light);
        width:$statusColumnSize;
        max-width:$statusColumnSize;
        min-width: $statusColumnSize;
    }

    tbody tr td:nth-child(3) {
        @extend .fixed-column;
        left:$idcPdcColumnSize;
        box-shadow: 3px 0px 3px 0px  rgba(0,0,0,0.1);
    }

    thead tr th:nth-child(3) { 
        @extend .fixed-column;
        background: var(--blue-clear-light);
        left:$idcPdcColumnSize;
        box-shadow: 3px 0px 3px 0px  rgba(0,0,0,0.1);
    }

    tbody tr td:nth-last-child(-n+1) {
        @extend .fixed-column;
        right:0; 
        background: var(--white);
        box-shadow: -3px 0px 3px 0px rgba(0,0,0,0.1);
    }

    thead tr th:nth-last-child(-n+1) { 
        @extend .fixed-column;
        right:0; 
        background: var(--blue-clear-light);
        box-shadow: -3px 0px 3px 0px rgba(0,0,0,0.1);
    }

    small {
        color: $gray-500;
    }

    .pdc-cell {
        i {
        margin-right: 15px;
        }
    }

    .survey-cell {
        border-left: 2px solid $gray-500;
        padding-left: 5px;

    }

    .actions {
        width: 80px;
        text-align: center;
    }
}
