.select{
  .disabled {
    > div {
      background-color: #e9ecef;
    }
  }
  .error{
    > div{
      border-color: var(--danger);
    }
  }
}
