@import "../../variable";

.survey-filter {
  margin-bottom: 10px;

  .actions {
    float: right;
    button {
      margin-right: 10px;
    }
  }

  .filter-header {
    .actions-wrapper {
      flex-grow: 0.5;
      max-width: 900px;
      button {
        background-color: #ffffff;
        box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05), 0 1px 3px 0 rgba(17, 95, 251, 0.1);
        margin-left: 5px;

        &:before {
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 30px;
          margin-right: 3px;
        }
      }
    }

    .search-wrapper {
      > * {
        margin-right: 10px;
      }

      .sz-form-group {
        width: 400px;
        border: 1px solid #cecece;
      }
    }
  }

  .form-fields {
    background-color: white;
    padding: 10px;
  }
}
