@import '~@suezenv/react-theme-components/assets/css/main.css';
@import '~react-sortable-tree/style.css';
@import './assets/fontawesome-free-6.5.2-web/scss/fontawesome.scss';
@import './assets/fontawesome-free-6.5.2-web/scss/solid.scss';
@import './assets/fontawesome-free-6.5.2-web/scss/regular.scss';
@import './assets/fontawesome-free-6.5.2-web/scss/brands.scss';
@import "./assets/main.scss";
@import "colors";

body {
  zoom: 0.8;
}

html, body, #root {
  height: 100%;
  overflow: hidden;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #adb5bd; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(133, 133, 133); 
}

.disabled {
  background: var(--btn-disabled) !important;

  a {
    cursor: default !important;
  }
}
