.header-wrapper {
  height: 50px;
  padding: 5px 0;

  .logo-wrapper img {
    height: 30px;
    margin-left: 10px;
  }

  .menu-selected {
    span {
      margin-right: 15px;
      .menu-title {
        position: relative;
        top: -4px;
      }
    }
  }
}