.workflow-status-override {
    .required:after  {
        content:"*";
        color:red;
    }
}
.icon-trash {
    display: flex;
    align-items: center;

    i {
        margin-top: 12px;
    }
}