.cp-asset-tab{
    .tree{
        background-color: var(--eca-gray1);
    }
    .side-layer
    {
        width: 100%;
        height: 100%;
        margin: 0px;
        position: fixed;
        z-index: 100;
        right: 0%;
        &-form{
            background-color:white;
            border:1px solid rgba(222, 222, 224, 0.57);
            overflow-y: auto;
            .xs{
                font-size: xx-small;
            }
        }
    }
}
