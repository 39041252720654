.migration {
    .required:after {
        content: "*";
        color: red;
    }

    .migration-form {
        .danger {
            color: var(--danger);
        }

        .info {
            color: var(--info);
        }
    }
}