.cp-tab-control-point {
  background-color: white;
  .header {
    h5 {
      margin-bottom: 0;
      margin-left: .8rem;
    }

    small {
      color: var(--gray-500);
      margin-left: .8rem;
    }
    margin-bottom: .8rem;
  }

  .has-status {
    &:before {
      content: '';
      display: block;
      float: left;
      margin-right: 5px;
      width: 4px;
      height: 40px;
      border-radius: 10px;
      background-color: var(--success);
    }
  }
}
