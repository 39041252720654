:root {
  --eca-blue1: #77B7FC;
  --eca-blue2: #BBDBFD;
  --eca-blue-dark: #030F40;
  --eca-disabled: #6c757d;
  --eca-gray1: #F8F9FA;

    /* survey Status */
  --status-toAchieve: #84DFFF;
  --status-toSpecify: #59B9FF;
  --status-toRedo: #FBD5CC;
  --status-toValidate: #2E88FF;
  --status-investigationCompleted: #30D158;
  --status-cancelled: #a1a1a1;
  --status-reportToSend: #DEB861;
  --status-fieldRealization: #EB4A65;
  --status-unproductive: #F5C4CC;
  --status-refused: #EB4A65;
  --status-abandoned: #ff8400;
  --status-toBeInvoiced: #F542E3;
}
