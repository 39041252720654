@import "~@suezenv/react-theme-components/assets/scss/1-settings/bootstrap";
@import "../variable";

$checkboxColumnWidth: 55px;
$firstColumnSize: 150px;

.survey-table-wrapper {
  height: 85%;

  div:nth-child(2) {
    height: 90%;
  }

  .survey-pagination {
    margin-top: 10px;
  }

  .action-buttons {
    margin-bottom: 1rem;

    .sz-btn-tertiary {
      cursor: default;
      background-color: #FFFFFF;
      box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05), 0 1px 3px 0 rgba(17, 95, 251, 0.1);
      margin-right: 1rem;
    }
  }

  .sticky-header {
    position: sticky;
    top: 0
  }

  .react-bootstrap-table {
    overflow: auto;
    height: 100%;
  }
}


.survey-table {
  td {
    vertical-align: middle !important;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .fixed-column {
    position: sticky;
    position: -webkit-sticky;
    z-index: 1;
    left: 0;
    background: var(--white);
  }

  tbody tr td:nth-child(1),
  tbody tr td:nth-child(2),
  tbody tr td:nth-child(3) {
    @extend .fixed-column;
  }

  thead tr th:nth-child(1),
  thead tr th:nth-child(2),
  thead tr th:nth-child(3) {
    background: var(--blue-clear-light);
    @extend .fixed-column;
    box-shadow: 3px 0 3px 0 rgba(0, 0, 0, 0.1);
  }

  thead tr th:nth-child(1),
  tbody tr td:nth-child(1) {
    width: $checkboxColumnWidth;
    padding-right: 0;
    padding-left: 15px;
  }

  tbody tr td:nth-child(2) {
    width: $firstColumnSize;
    max-width: $firstColumnSize;
    min-width: $firstColumnSize;
    left: $checkboxColumnWidth;
  }

  thead tr th:nth-child(2) {
    width: $firstColumnSize;
    max-width: $firstColumnSize;
    min-width: $firstColumnSize;
    left: $checkboxColumnWidth;
  }

  tbody tr td:nth-child(3) {
    left: $checkboxColumnWidth + $firstColumnSize;
    box-shadow: 3px 0 3px 0 rgba(0, 0, 0, 0.1);
  }

  thead tr th:nth-child(3) {
    left: $checkboxColumnWidth + $firstColumnSize;
    box-shadow: 3px 0 3px 0 rgba(0, 0, 0, 0.1);
  }

  tbody tr td:nth-last-child(-n+1) {
    @extend .fixed-column;
    right: 0;
    background: var(--white);
    box-shadow: -3px 0px 3px 0px rgba(0, 0, 0, 0.1);
  }

  thead tr th:nth-last-child(-n+1) {
    @extend .fixed-column;
    right: 0;
    background: var(--blue-clear-light);
    box-shadow: -3px 0px 3px 0px rgba(0, 0, 0, 0.1);
  }

  small {
    color: $gray-500;
  }

  .pdc-cell {
    i {
      margin-right: 15px;
    }
  }

  .survey-cell {
    border-left: 2px solid $gray-500;
    padding-left: 5px;

  }

  .statut-cell {
    small {
      display: block;
      float: left;
    }

    &:before {
      content: '';
      display: block;
      float: left;
      margin-right: 5px;
      width: 4px;
      height: 40px;
      border-radius: 10px;
    }

    @each $key,
    $value in $statusList {
      &.status-#{$value}:before {
        background-color: var(--status-#{$value});
      }
    }
  }

  .actions {
    width: 80px;
    text-align: center;
  }
}