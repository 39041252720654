.dropzone-wrapper {
  .dropzone-text {
    display: flex;
    height: 200px;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    background: white;
    border: 1px solid var(--primary);
  }
}
