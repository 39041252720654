.params{
    .header {
        h5 {
        margin-bottom: 0;
        margin-left: .8rem;
        }
        p {
            color: var(--gray-500);
            margin-left: .8rem;
        }
        i{
            font-size: 0.9rem;
             margin-right: 0.3rem;
        }
        margin-bottom: .8rem;
    }
}
