.control-point-view {
  .header {
    h5 {
      margin-bottom: 0;
      margin-left: .8rem;
    }

    small {
      color: var(--gray-500);
      margin-left: .8rem;
    }
    margin-bottom: .8rem;
  }
  .cp-header {
    .cp-address {
      color: var(--gray);
      i {
        font-size: 0.9rem;
        margin-right: 0.3rem;
      }
    }

    .cp-actions {
      i {
        font-size: 1.2rem;
        margin-right: 0.3rem;
      }

    }
  }
}
