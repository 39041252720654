.json-view-wrapper {
  bottom: 0;
  right: 0;  
  width: 100%;
  padding: 0 5px;

  .copy-wrapper {
    position: absolute;
    top: 0;
    right: 6px;
    button {
      height: 35px;
    }
  }
  textarea {
    width: 100%;
    border: 2px solid black;
  }
}
