.cp-contact-tab {
    .survey-contact {
        .header,button{
            color: var(--eca-disabled);
        }
        .sz-left-border::after{
            background-color: var(--gray-200);
        }
    } 
}
