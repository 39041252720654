.well {
  background-color: var(--gray-100);
  padding: 0.2rem;
}

.display-value {
  .title {
    font-size: 0.8rem;
    color: var(--gray-600);
    display: block;

  }

  .content {
    font-weight: bold;
    color: var(--secondary);
    display: block;
    min-height: 1rem;
  }

  min-height: 1rem;
}

.nl2br {
  white-space: pre-line;
}

.main-content .RRT__container .RRT__tabs {
  margin-bottom: 0;
}