@import "../variable";
.survey-view{
    .survey-address{
        font-size: 12px;
        line-height: 12px;
        color: var(--gray);
      i {
        font-size: 0.9rem;
        margin-right: 0.3rem;
      }
    }
    .survey-details{
        .workflow{
          height: 300px; 
        }
        .result{
          &:before {
            content: '';
            display: block;
            float: left;
            margin-right: 8px;
            border-radius: 50%;
            margin-top: 10%;
            width: 10px;
            height: 10px;
            background-color: var(--success);
          }
        }
    }
    .survey-mail{
        .long-text{
          overflow-wrap: break-word;
        }
        
        .has-border{
          &:before{
            content: '';
            display: block;
            float: left;
            margin-right: 1px;
            width: 2px;
            height: 40px;
            border-radius: 10px;
            background-color: var(--gray-500);
          }
        }
    }
}
.survey-mail-modal {
  .modal-content{
    background-color: var(--gray-0);
  }
}
