.pdc-edit{
    --primary-brand: var(--eca-blue1);
    --primary-contrast :var(--eca-blue-dark);
    .sz-header-stepper__step{
        margin-right: 0.3rem;
    }
    span.required:after  {
        content:"*";
        color:red;
    }
}

