.closed-survey-reference {
    margin: 30px 0 50px 0;
}
.pdc-attachment-container {
    display: flex;
    gap: 20px;

    > div {
        width: 50%;
    }
}
.send-button {
    margin-bottom: 30px;
}