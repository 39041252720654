@import "~@suezenv/react-theme-components/assets/scss/1-settings/bootstrap";


$firstColumnSize: 150px;

.campaign-table-wrapper {
  height: 85%;
  div:nth-child(1) {
    height: 100%;
  }
  .sticky-header{
    position: sticky;
    top:0
  }
  .react-bootstrap-table {
    overflow: auto;
    height: 100%;
  }
}

.campaign-table {
  td {
    vertical-align: middle !important;
  }
  thead{
    position: sticky;
    top:0;
    z-index: 2;
  }
  .fixed-column {
    position: sticky; 
    position: -webkit-sticky;
    z-index:1; 
    left:0; 
    background: var(--white);
  }

  tbody tr td:nth-child(1) {
    @extend .fixed-column;
    padding: 5px;
    width:$firstColumnSize;
    max-width:$firstColumnSize;
    min-width: $firstColumnSize;
  }

  thead tr th:nth-child(1) { 
    @extend .fixed-column;
    padding: 5px;
    padding-left: 15px;
    background: var(--blue-clear-light);
    width:$firstColumnSize;
    max-width:$firstColumnSize;
    min-width: $firstColumnSize;
  }

  tbody tr td:nth-child(2) {
    @extend .fixed-column;
    left:$firstColumnSize;
    box-shadow: 3px 0px 3px 0px  rgba(0,0,0,0.1);
  }

  thead tr th:nth-child(2) { 
    @extend .fixed-column;
    background: var(--blue-clear-light);
    left:$firstColumnSize;
    box-shadow: 3px 0px 3px 0px  rgba(0,0,0,0.1);
  }

  tbody tr td:nth-last-child(-n+1) {
    @extend .fixed-column;
    right:0; 
    background: var(--white);
    box-shadow: -3px 0px 3px 0px rgba(0,0,0,0.1);
  }

  thead tr th:nth-last-child(-n+1) { 
    @extend .fixed-column;
    right:0; 
    background: var(--blue-clear-light);
    box-shadow: -3px 0px 3px 0px rgba(0,0,0,0.1);
  }

  small {
    color: $gray-500;
  }

  .pdc-cell {
    i {
      margin-right: 15px;
    }
  }

  .survey-cell {
    border-left: 2px solid $gray-500;
    padding-left: 5px;

  }

  .actions {
    width: 80px;
    text-align: center;
  }
}
