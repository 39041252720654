@import "~@suezenv/react-theme-components/assets/scss/1-settings/bootstrap";


.content-wrapper {
  .main-content {
    margin-left: 70px;
  }
}

.navigation-wrapper {
  position: absolute;
  left: 0;
  top: 50px;
  z-index: 100;

  .parameter-menu-separator {
    margin-top: 100px;
  }
}

span.app-name {
  display: block;
  text-align: center;
  color: white;
  background-color: $expert-blue;
  border-top: 4px solid $blue-brand-light;
  padding-top: 10px;
}
