.timeline-horizontal {
    list-style: none;
    position: relative;
	padding: 0px;
	&:before {
        position: absolute;
        content: "";
        background-color:var(--gray-dark);
        height: 3px;
        bottom: 38px;
        width: 100%;
    }
	.timeline-item {
		height: 50px;
        .timeline-badge 
        {
            width: 20px;
            height: 20px;
            position: absolute;
            background-color: var(--gray-dark);
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
        }
        .timeline-text
        {    
            margin-top: 30px;
        }
        &:first-child 
        {
            .timeline-badge
            {
                width: 30px;
                height: 30px;
                bottom:25px
            }
        }
        &:nth-child(n+2)
        {
            &:last-child{
                .timeline-badge
                {     
                    right: 0;
                    width: 30px;
                    height: 30px;
                    bottom:25px;
                }
                .timeline-text
                {    
                    text-align: right;
                }
            }
        }
       	
        
	}
}
