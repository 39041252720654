$statusList : "toAchieve", "cancelled", "fieldRealization", "unproductive", "toValidate",  "refused", "toSpecify", "toRedo", "abandoned", "reportToSend", "investigationCompleted", "toBeInvoiced";


.survey-details {
  .timeline-item {
    @each  $value in $statusList {
      &.timeline-#{$value} {
        .timeline-badge {
          background-color: var(--status-#{$value});
        }
      }
    }
  }
  .has-status {
      &:before {
      content: '';
      display: block;
      float: left;
      margin-right: 5px;
      width: 4px;
      height: 40px;
      border-radius: 10px;
    }
      @each  $value in $statusList {
        &.status-#{$value}:before {
              background-color: var(--status-#{$value});
      }
    }
  }
}
