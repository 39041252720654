.asset-item{
    .collapse-button,
    .expand-button {
        background-color: white;
        left:-30px;
        border: none;
        position: absolute;
        width: 24px;
        height: 24px;
        padding: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    .tree-row {
        width: 426px;
        background-color: #FFFFFF;
        box-shadow: 0 0 1px 0 rgba(17,95,251,0.05), 0 1px 3px 0 rgba(17,95,251,0.1);
        display: inline-block;
        vertical-align: middle;
        position: relative;
        height: 100%;
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        &:before {
            content: '';
            display: block;
            float: left;
            margin-right: 5px;
            width: 4px;
            height: 80%;
            border-radius: 10px;
            background-color: var(--eca-blue2);
        }
  }
    
}
